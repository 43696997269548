import React, { useEffect, useRef } from 'react';
import { Button } from '../Button';
import SectionIntro from '../SectionIntro';
import Img from 'gatsby-image';

import { inViewTriggerThresholds } from '../../animationGlobals';

import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

export const IconCallout = ({ title, bodyText, icon }) => {
  return (
    <div className="icon-callout">
      <div className="icon-callout__icon">
        <Img fluid={icon.fluid} alt={icon.description} />
      </div>
      <h5 className="icon-callout__title">{title}</h5>
      <p className="icon-callout__body">{bodyText.bodyText}</p>
    </div>
  );
};

const CalloutsSection = ({ title, subtitle, callouts, ctaLabel, ctaUrl }) => {
  const hasIntro = title && subtitle;
  const hasCTA = ctaLabel && ctaUrl;

  const sectionRef = useRef();
  const introRef = useRef();
  const bodyRef = useRef();
  const footerRef = useRef();

  useEffect(() => {
    const sectionEl = sectionRef.current;
    const introEl = introRef.current;
    const bodyEl = bodyRef.current;
    const footerEl = footerRef.current;

    const toCommon = {
      duration: 0.5,
      ease: 'power4.out',
    };

    const delayCommon = `<${toCommon.duration * 0.25}`;

    const FadeIn = [
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 1,
        ease: 'power2.out',
      },
      delayCommon,
    ];

    const InFromBottom = [
      {
        opacity: 0,
        y: '25%',
      },
      {
        opacity: 1,
        y: '0%',
        ...toCommon,
      },
      delayCommon,
    ];

    const animation = gsap
      .timeline({})
      .fromTo(introEl, ...InFromBottom)
      .fromTo(bodyEl, ...FadeIn)
      .fromTo(footerEl, ...InFromBottom);

    const trigger = ScrollTrigger.create({
      trigger: sectionEl,
      ...inViewTriggerThresholds,
      animation: animation,
    });

    return () => {
      animation.progress(1);
      animation.kill();
      trigger.kill();
    };
  }, []);

  return (
    <section ref={sectionRef} className={`callouts-section`}>
      {/* INTRO */}
      {hasIntro && (
        <div ref={introRef} className="callouts-section__intro">
          <SectionIntro title={title} subtitle={subtitle} />
        </div>
      )}
      {/* BODY */}
      <div ref={bodyRef} className={`callouts-section__body`}>
        <div className={`callouts`}>
          {callouts.map((callout) => {
            return <IconCallout {...callout} key={callout.title} />;
          })}
        </div>
      </div>

      {/* FOOTER */}

      {hasCTA && (
        <div ref={footerRef} className={`callouts-section__footer`}>
          <Button theme="dark" href={ctaUrl}>
            {ctaLabel}
          </Button>
        </div>
      )}
    </section>
  );
};

export default CalloutsSection;
