import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/Layout';
import { deviceDataFromDeviceAsset } from '../utils/contentfulHelpers';
import AlertBar from '../components/AlertBar/AlertBar';
import DeviceSection from '../components/DeviceSection';
import DemoBanner from '../components/DemoBanner';
// import Testimonial from '../components/Testimonial/Testimonial';
import CalloutsSection from '../components/CalloutsSection/CalloutsSection';
import PartnersSection from '../components/PartnersSection/PartnersSection';
import NewsletterSection from '../components/NewsletterSection/NewsletterSection';
import ResourcesSection from '../components/ResourcesSection/ResourcesSection';
import SiteMeta from '../components/SiteMeta';
import HeroCarousel from '../components/HeroCarousel/HeroCarousel';
// import {Helmet} from "react-helmet";

const Home = ({ data }) => {
  const { contentfulStaticHomePage: {
    alertBar,
    heroCarousel,
    featuresSection,
    transformationModule,
    demoBanner,
    testimonial,
    partnersSection,
    newsletterSection,
    resourcesSection,
    metaTitle,
    metaDescription
  } } = data;
  return (
    <Layout>
      <SiteMeta title={metaTitle} description={metaDescription} />
      <AlertBar {...alertBar} />
      <HeroCarousel items={heroCarousel} />
      <CalloutsSection {...featuresSection} />
      {/* <Testimonial {...testimonial} /> */}
      <DeviceSection barTheme="yellow" layoutVariant="deviceRight" {...transformationModule} {...deviceDataFromDeviceAsset(transformationModule.deviceAsset)} />
      <PartnersSection {...partnersSection} />
      <DemoBanner {...demoBanner} theme="yellow" />
      <ResourcesSection {...resourcesSection} />
      <NewsletterSection {...newsletterSection} />
    </Layout>
  )
}

export default Home

export const query = graphql`
  query {
    contentfulStaticHomePage(slug: {eq: "homepage-v3"}) {
      metaTitle
      metaDescription
      alertBar {
        ...AlertBar
      }
      heroCarousel {
        ...HeroCarouselItem
      }
      partnersSection {
        ...PartnersSection
      }
      featuresSection {
        ...CalloutSection
      }
      transformationModule {
        ...DeviceNavigator
      }
      demoBanner {
        ...DemoBanner
      }
      testimonial {
        ...Testimonial
      }
      newsletterSection {
        ...NewsletterSection
      }
      resourcesSection {
        ...ResourcesSection
      }
    }
  }
`;
