import React, { useEffect, useRef } from 'react';
import { Button } from '../Button';
import MediaItem from '../MediaItem';
import SectionIntro from '../SectionIntro';

import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { inViewTriggerThresholds } from '../../animationGlobals';

export default function PartnersSection({
  title,
  subtitle,
  logos,
  ctaLabel,
  ctaUrl,
}) {
  const sectionRef = useRef();
  const introRef = useRef();
  const bodyRef = useRef();
  const footerRef = useRef();

  useEffect(() => {
    // const sectionEl = sectionRef.current;
    // const introEl = introRef.current;
    // const bodyEl = bodyRef.current;
    // const footerEl = footerRef.current;

    // const toCommon = {
    //   duration: 0.5,
    //   ease: 'power4.out',
    // };

    // const delayCommon = `<${toCommon.duration * 0.5}`;

    // const InFromBottom = [
    //   {
    //     opacity: 0,
    //     y: '25%',
    //   },
    //   {
    //     opacity: 1,
    //     y: '0%',
    //     ...toCommon,
    //   },
    //   delayCommon,
    // ];

    // const animation = gsap
    //   .timeline({})
    //   .fromTo(introEl, ...InFromBottom)
    //   .fromTo(bodyEl, ...InFromBottom)
    //   .fromTo(footerEl, ...InFromBottom);

    // const trigger = ScrollTrigger.create({
    //   trigger: sectionEl,
    //   ...inViewTriggerThresholds,
    //   animation: animation,
    // });

    // return () => {
    //   animation.progress(1);
    //   animation.kill();
    //   trigger.kill();
    // };
  }, []);

  return (
    <section ref={sectionRef} className="partners-section">
      <div ref={introRef} className="partners-section__header">
        <SectionIntro
          width="full"
          theme={'dark'}
          title={title}
          subtitle={subtitle}
        />
      </div>

      <div ref={bodyRef} className="partners-section__body">
        <div className="partner-logos">
          {logos.map((logo, index) => {
            return (
              <div
                className="partner-logo"
                key={`${logo.description}-${index}`}
              >
                <MediaItem asset={logo} />
              </div>
            );
          })}
        </div>
      </div>
      <div ref={footerRef} className="partners-section__footer">
        <Button theme="dark" href={ctaUrl}>
          {ctaLabel}
        </Button>
      </div>
    </section>
  );
}
